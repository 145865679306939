import { useEffect, useState, useRef, FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import PgeButton from '../../pge-button/_PgeButton';
import RichText from '../../rich-text';
import useAttrs from '../../../hooks/useAttrs';
import { Omits, ProgramCardProps as Props } from './types.d';
import { ColorName, Shadow } from '../../../constants';

const ProgramCard: FC<Props> = ({
  heading,
  headingLink,
  leadStatement,
  shortDescription,
  image,
  ctaButton,
  ctaButtonLink,
  supportingCta,
  supportingCtaLink,
  programBadge = 'None',
  colorBackground = 'None',
  column = '1',
  ...props
}) => {
  const ctaButtonType = ctaButton?.buttonType || 'Primary';

  // Vars
  const hasBadge = programBadge !== 'None';
  const badgeType = programBadge.replace(/\s/g, '');
  const withContent = shortDescription ? 'with-Content' : '';

  const [row, setRow] = useState(false);
  const listen = useRef(true);
  const { className = '', ...attrs } = useAttrs<Props>(props, Omits);

  const theme = useTheme()
  const { palette } = theme;
  const { white } = palette.common;
  const text = palette.text.primary;
  const popular = palette.success.main;
  const nueva = palette.warning.main;
  const lightGrey = palette.grey.A100;
  const darkGrey = palette.grey[700];

  useEffect(() => {
    if (column === '1') {
      const size = () => {
        if (listen.current && window.innerWidth > 768) {
          setRow(true);
          listen.current = false;
        }
        if (!listen.current && window.innerWidth < 767) {
          setRow(false);
          listen.current = true;
        }
      };

      window.addEventListener('resize', size, { passive: true });
      size();

      return () => {
        window.removeEventListener('resize', size);
      };
    }
  });

  return (
    <article
      css={{
        width: '100%',
        display: 'flex',
        boxSizing: 'border-box',
        contain: 'content',
        padding: theme.spacing(6, 0, 4),
        marginTop: theme.spacing(3),
        position: 'relative',
        flexDirection: 'column',
        borderRadius: '5px',
        boxShadow: Shadow.ONE,

        backgroundColor: colorBackground === 'Grey' ? ColorName['Grey'] : ColorName['None'],

        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(6, 0),
        },

        ...(column === '1' ? {
          [theme.breakpoints.up('sm')]: {
            paddingRight: theme.spacing(4),
            flexDirection: 'row',
          },
        } : column === '2' ? {
          [theme.breakpoints.up('sm')]: {
            width: 'calc(50% - 12px)',
          },
        } : column === '3' ? {
          [theme.breakpoints.up('sm')]: {
            width: 'calc(33.33% - 16px)',
          },
        } : {})
      }}
      aria-label={heading || undefined}
      {...attrs}
    >
      {hasBadge && (
        <Typography
          css={{
            width: theme.spacing(17),
            height: theme.spacing(4),
            position: 'absolute',
            top: 0,
            left: 0,
            textAlign: 'center',
            lineHeight: theme.spacing(4),
            textTransform: 'uppercase',
            borderRadius: '5px 0',

            ...(badgeType === 'MostPopular' ? {
              color: white,
              background: popular,
            } : badgeType === 'New' ? {
              color: white,
              background: nueva,
            } : (badgeType === 'SoldOut' || badgeType === 'Full') ? {
              color: darkGrey,
              background: lightGrey,
            } : {}),
          }}
          component="p"
          variant="subtitle2"
        >
          <small>{programBadge}</small>
        </Typography>
      )}
      {image?.file?.url && (
        <picture css={{
          width: theme.spacing(17),
          height: theme.spacing(17),
          overflow: 'hidden',

          '& img': {
            display: 'block',
            width: '75%',
            height: '75%',
            margin: 'auto',
          },
        }}>
          <img src={image.file.url} alt="" width="100" height="100" />
        </picture>
      )}
      <div css={{
        display: 'flex',
        width: '100%',
        flex: 1,
        padding: theme.spacing(0, 3),
        flexDirection: 'column',
        alignItems: 'start',

        ...(column === '1' ? {
          [theme.breakpoints.up('sm')]: {
            flex: 1,
          }
        } : {}),

        '& h2': {
          marginTop: '1rem',
          fontWeight: 700,
          color: text,
        },
      }}>
        {heading && (
          <Typography variant="h1">
            {headingLink && (
              <PgeButton link={headingLink} display="Inline" theme="Text">
                {heading}
              </PgeButton>
            )}
          </Typography>
        )}
        {leadStatement && <Typography variant="h2">{leadStatement}</Typography>}
        <RichText css={{
          marginTop: '1rem',
          color: text,

          '.col-2 &, .col-3 &': {
            [theme.breakpoints.up('sm')]: {
              marginBottom: '2rem',
            },
          },
        }}>
          {shortDescription}
        </RichText>
        {!row && ctaButtonLink && (
          <PgeButton
            css={{
              marginTop: '2rem',
              alignSelf: 'stretch',

              ...(column === '1' ? {
                [theme.breakpoints.up('sm')]: {
                  marginTop: 0,
                  alignSelf: 'center',

                  ...(withContent ? {
                    marginTop: '2.25rem',
                    alignSelf: 'start',
                  } : {})
                },
              } : column === '2' ? {
                [theme.breakpoints.up('sm')]: {
                  marginTop: 'auto',
                  alignSelf: 'start',
                },
              } : column === '3' ? {
                [theme.breakpoints.up('sm')]: {
                  marginTop: 'auto',
                  alignSelf: 'start',
                },
              } : {})
            }}
            link={ctaButtonLink}
            theme={ctaButtonType}
          >
            {ctaButton?.buttonText}
          </PgeButton>
        )}
        {supportingCtaLink && (
          <PgeButton
            css={{
              marginTop: '1rem',
              alignSelf: 'stretch',

              [theme.breakpoints.up('sm')]: {
                alignSelf: 'start',
              },
            }}
            link={supportingCtaLink}
            theme="Text"
          >
            {supportingCta?.buttonText}
          </PgeButton>
        )}
      </div>
      {row && ctaButtonLink && (
        <PgeButton
          css={{
            marginTop: '2rem',
            alignSelf: 'stretch',

            ...(column === '1' ? {
              [theme.breakpoints.up('sm')]: {
                marginTop: 0,
                alignSelf: 'center',

                ...(withContent ? {
                  marginTop: '2.25rem',
                  alignSelf: 'start',
                } : {})
              },
            } : column === '2' ? {
              [theme.breakpoints.up('sm')]: {
                marginTop: 'auto',
                alignSelf: 'start',
              },
            } : column === '3' ? {
              [theme.breakpoints.up('sm')]: {
                marginTop: 'auto',
                alignSelf: 'start',
              },
            } : {})
          }}
          link={ctaButtonLink}
          theme={ctaButtonType}
        >
          {ctaButton?.buttonText}
        </PgeButton>
      )}
    </article>
  );
}

export default ProgramCard;
